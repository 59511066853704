import { Box, Flex, Text, Avatar, Button, Divider, VStack } from '@chakra-ui/react';
import moment from 'moment';

const convertToCSV = (gamebet) => {
  let str = '';
  const game = gamebet.game
  str = str.concat(['','Team 1','X',  'Team 2'].join(','), '\n')
  str = str.concat(['',game.team_1.name,'X',  game.team_2.name].join(','), '\n')
  str = str.concat(['Betting House','','',  ''].join(','), '\n')
  const bookmakers = game.game_bookmarkers
  for (let i = 0; i < bookmakers.length; i++) {
    str = str.concat([bookmakers[i].bookmarker.name ,bookmakers[i].team_1_odd,bookmakers[i].draw_odd,bookmakers[i].team_2_odd].join(','), '\n')
  }
  str = str.concat(['Calculations','','',''].join(','), '\n')
  const stats = game.game_statistics[0];
  const stats_keys = Object.keys(stats)
  for (let i = 0; i < stats_keys.length; i++) {
    str = str.concat([stats_keys[i],stats[stats_keys[i]],'',''].join(','), '\n')
  }

  return str;
};

const downloadCSV = (gamebet) => {
   const game = gamebet.game

  const fileName = `${game.team_1.name}x${game.team_2.name}`;
  const csvData = new Blob([convertToCSV(gamebet)], { type: 'text/csv' });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


const   BetCard = ({ gamebet, betValue, bestOdd }) => {
    const game = gamebet.game;
    const stats = game.game_statistics[0];
    const bet_reccomendation = stats.bet_reccomendation;
    let odds_mean = 0
    let std_deviation = stats.odds_std_ratio_game
    if (bet_reccomendation === 1) {
        odds_mean = game.game_statistics[0].odds_mean_team_1
    } else if (bet_reccomendation === -1) {
      odds_mean = game.game_statistics[0].odds_mean_team_2
    } else {
        odds_mean = odds_mean = game.game_statistics[0].odds_mean_draw
    }
    return (
    
  <Box bg="gray.800" borderRadius="md" p={4} w="full" order={moment(game.date).unix()}>
    <Text color="whiteAlpha.700" fontSize="xs" mb={2}>
      {moment(game.date).format("MMMM Do YYYY, h:mm:ss a")} <Button size={'xs'} background={'green'} color={'white'} onClick={() => downloadCSV(gamebet)}>Export CSV</Button>
    </Text>
    
    <Text color="white" fontWeight="bold" fontSize="lg" mb={2}>
      {game.competition.name} 
    </Text>
    <Flex align="center" justify="space-between" alignItems={'flex-start'} mb={2}>
    <VStack flex={"1 1 49%"}>
      <Flex align="center">
        <Avatar src={game.team_1.logo}  mr={2} name={game.team_1.name} />
        <Text color="white" fontWeight="bold">{game.team_1.name}</Text>
        {game.team_1_score !== null && <Text color="white" fontWeight="bold"> {game.team_1_score} </Text>}
      </Flex>
      {bet_reccomendation === 1 && <Button colorScheme="green" w="full">Bet on</Button>}
      
      </VStack>
      <VStack flex={"1 1 2%"}  color={'white'}><Text>X</Text></VStack>
      <VStack flex={"1 1 49%"} ju>
    
      <Flex align="center">
        <Text color="white" fontWeight="bold">{game.team_2.name}</Text>
        <Avatar src={game.team_2.logo}  ml={2} name={game.team_2.name} />

        {game.team_2_score !== null && <Text color="white" fontWeight="bold"> {game.team_2_score} </Text>}
      </Flex>
      {bet_reccomendation === -1 && <Button colorScheme="green" w="full">Bet on</Button>}
      </VStack>
    </Flex>
    {bet_reccomendation == 0 && <Button colorScheme="green" w="full">Bet on Draw</Button>}

    <Flex justify="space-between" align="center" mb={2}>
      <Text color="whiteAlpha.700" fontSize="sm">Pot Percentage</Text>
      <Text color="white" fontSize="sm">{(gamebet.weight*100).toFixed(2)}%</Text>
    </Flex>

    <Flex justify="space-between" align="center" mb={2}>
      <Text color="whiteAlpha.700" fontSize="sm">Odds Mean</Text>
      <Text color="white" fontSize="sm">{odds_mean}</Text>
    </Flex>

    <Flex justify="space-between" align="center" mb={2}>
      <Text color="whiteAlpha.700" fontSize="sm">PCM</Text>
      <Text color="white" fontSize="sm">{stats.pcm_game}</Text>
    </Flex>
    <Flex justify="space-between" align="center" mb={2}>
      <Text color="whiteAlpha.700" fontSize="sm">STD Deviation</Text>
      <Text color="white" fontSize="sm">{std_deviation}</Text>
    </Flex>
    <Flex justify="space-between" align="center" mb={2}>
      <Text color="whiteAlpha.700" fontSize="sm">Weight</Text>
      <Text color="white" fontSize="sm">{(parseFloat(stats.pcm_game)+parseFloat(std_deviation))/2}</Text>
    </Flex>
    <Flex justify="space-between" align="center" mb={2}>
      <Text color="whiteAlpha.700" fontSize="sm">Best Bookmakers</Text>
      <Text color="white" fontSize="sm">{bestOdd[game.id]?.bookmarker.name}</Text>
    </Flex>
    <Flex justify="space-between" align="center" mb={2}>
      <Text color="whiteAlpha.700" fontSize="sm">Best Odds</Text>
      <Text color="white" fontSize="sm">{bestOdd[game.id]?.odd}</Text>
    </Flex>
    <Divider borderColor="gray.700" />
    {<Box bg="gray.700" p={2} borderRadius="md">
      <Flex justify="space-between" align="center">
        <Text color="white" fontWeight="bold">Suggested Value</Text>
        <Text color="green.400" fontWeight="bold">{gamebet.value}$</Text>
      </Flex>
    </Box>}


  </Box>
)};

export default BetCard;
