import axios from 'axios';

//const BASE_URL = 'http://localhost:8000/api';

const BASE_URL = 'https://backendibet.gamaliel.dev/api';
//  const BASE_URL = 'http://ec2-3-144-251-146.us-east-2.compute.amazonaws.com/api';

const api = axios.create({
    baseURL: BASE_URL,// replace with your API base URL
    headers: {
        'Content-Type': 'application/json'
    }
});

const login = (data) => api.post('/signin/', data);

const register = (data) => api.post('/signup/', data);

const refreshToken = () => api.post('/token/refresh/', {refresh: localStorage.getItem('refresh')});

const getPrivateApi = () => {
    const instance = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }});
        instance.interceptors.response.use((response) => {
            return response;
        },  (error) => {
                    console.log(error.response.status);
                    if (error.response.status === 401) {
                        const originalRequest = error.config;

                        refreshToken().then((response) => {
                            localStorage.setItem('token', response.data.access);
                            originalRequest.headers.Authorization = `Bearer ${ response.data.access}`;
                            return axios(originalRequest);
                        }).catch((error) => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('refresh');
                            window.location.href = '/login';
                        });
                    }
                    return Promise.reject(error);
                });
       return instance;
}
const getGames = () => getPrivateApi().get('/games/');

const getBookmarkers = () => getPrivateApi().get('/bookmarkers/')

const updateBookmarker = (bookmarkerId, active) => getPrivateApi().post(`/bookmarkers/${bookmarkerId}/activate`, {active})
const getGamesBettable = (token) => getPrivateApi().get('/games/bettable/');

const getCurrentUser = () => getPrivateApi().get('/user/');

const getSports = () => getPrivateApi().get('/sports/');

const getTeams = (sport = undefined) =>{ 
    console.log(JSON.stringify(sport));
    const url = sport ? `/teams/?sport=${sport}` : '/teams/';
    return getPrivateApi().get(url);
}

const getCompetitions = (sport = undefined) => {
    const url = sport ? `/competitions/?sport=${sport}` : '/competitions/';
    return getPrivateApi().get(url);
}

const getGameList = (params) => {
    let urlparams = '' 
    for (const [key, value] of Object.entries(params)) {
        urlparams += `${key}=${value}&`
    }
    return getPrivateApi().get(`/game_list/?${urlparams}`);
} 

const createPortfolio = (data) => getPrivateApi().post('/portfolio/', data);

const getPortfolios = () => getPrivateApi().get('/portfolio/');

const getPortfolio = (id) => getPrivateApi().get(`/portfolio/${id}/`);

const subscribe = (portfolioId) => getPrivateApi().post(`/portfolio/${portfolioId}/subscribe/`);

const unsubscribe = (portfolioId) => getPrivateApi().post(`/portfolio/${portfolioId}/unsubscribe/`);

const loadSubscriptions = () => getPrivateApi().get('/portfolio/subscribed/');

const loadBets = (id) => getPrivateApi().get(`/portfolio/${id}/bets/`);

const bet = (id, data) => getPrivateApi().post(`/portfolio/${id}/bet/`, data)

const getMyBets = () => getPrivateApi().get('/bets/');

const getABet = (id) => getPrivateApi().get(`/bet/${id}/`);

const getPortfolioBet = (id) => getPrivateApi().get(`/portfolio/${id}/bet/`);

const getAdminPortfolios = (params) => getPrivateApi().get('/admin/portfolios/', {params});

const getAdminPortfolio = (id) => getPrivateApi().get(`/admin/portfolio/${id}/`);

const getAdminGamesFilters = () => getPrivateApi().get(`/admin/game/filters/`);

const impersonate = (email) => getPrivateApi().get(`admin/impersonate/${email}/` );

const getAdminGames = (params) => getPrivateApi().get('/admin/games/', {params});

const getAdminGame = (id) => getPrivateApi().get(`/admin/game/${id}/`);

const putAdminGame = (data) => getPrivateApi().put(`/admin/game/${data.id}/`, data);

const getSportsApi = () => getPrivateApi().get('/odds/sports/');

const getCompetitionsApi = () => getPrivateApi().get('/odds/competition/');

const getEventsApi = (sport) => getPrivateApi().get('/odds/events/?sport='+sport);

const getReportGamesPerCompetition = (competition) => getPrivateApi().get(`/odds/competition/games?competition=${competition}`, {responseType: 'blob', headers: {'Content-Type': 'text/csv'}});

const getReportGamesPerSport = (sport) => getPrivateApi().get(`/odds/sport/games?sport=${sport}`);

const putReport = (data) => getPrivateApi().post(`/reports/`, data);


export default {login, register, getGames, getGamesBettable,
                getBookmarkers, updateBookmarker, getCurrentUser,
                getSports, getTeams, getCompetitions, getGameList,
                getPortfolios, getPortfolio, subscribe, loadSubscriptions,
                createPortfolio, putReport, impersonate, getPrivateApi,
                loadBets, bet,getMyBets, getABet, getPortfolioBet,
                getAdminPortfolios, getAdminPortfolio, getAdminGames,
                getAdminGame, putAdminGame,getAdminGamesFilters, unsubscribe,
                getSportsApi, getCompetitionsApi, getEventsApi, getReportGamesPerCompetition, getReportGamesPerSport
            };