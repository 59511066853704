import React, { useState } from "react";
import {
  Box,
  Tab,
  TableContainer,
  Table,
  Td,
  Tr,
  Thead,
  Th,
  Tbody,
  Button
} from "@chakra-ui/react";
import api from "../../api";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import moment from "moment";

const OddsApiScreen = () => {
  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);

  React.useEffect(() => {
    const fetchSports = async () => {
      try {
        const response = await api.getSportsApi();
        console.log(response.data[0]);
        setSports(response.data[0]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchSports();
  }, []);

    const fetchEvents = async (sport) => {
        setLoading(true);
        try {
            const response = await api.getEventsApi(sport);
            console.log(response.data);
            setEvents(response.data[0]);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }
  return (
    <Box w={"100%"} h={"auto"} >
      {loading && <p>Loading...</p>}
      {!loading && events.length==0 && 
      <TableContainer  >
        <Table variant='striped' color={"#000"}>
          <Thead>
            <Tr>
              <Th>Key</Th>
              <Th>Competition</Th>
              <Th>Group</Th>
               <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
          {sports.map((sport) => {
            return (
              <Tr key={sport.key}>
                <Td>{sport.key}</Td>
                <Td>{sport.title}</Td>
                <Td >{sport.group}</Td>
                <Td>
                  <Button colorScheme="teal" size="sm"
                    onClick={() => {
                        setSelectedSport(sport.key);
                        fetchEvents(sport.key);
                    }}
                  >
                    Load Events
                  </Button>
                  <Button colorScheme="teal" size="sm" onClick={async ()=> {
                    const csvData = new Blob([(await api.getReportGamesPerCompetition(sport.title)).data], {type: 'text/csv;charset=utf-8;'});
                    const csvURL = URL.createObjectURL(csvData);
                    const link = document.createElement('a');
                    link.href = csvURL;
                    link.download = `${sport.key}.csv`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    
                  }}>
                    extract games csv
                  </Button>
                  </Td>
              </Tr>
            );            
          })}
            </Tbody>
        </Table>
        </TableContainer>
      }
      {!loading && events.length>0 &&
            <TableContainer  >
        <Table variant='striped' color={"#000"}>
        <Thead>
            <Tr>
            <Th>Date</Th>
            <Th>Home Team</Th>
            <Th>Away Team</Th>
            <Th>Actions</Th>
            </Tr>
      </Thead>
      <Tbody>
        {events.map((event) => {
          return (
            <Tr key={event.id}>
              <Td>{moment(event.date).format("DD/MM/YYYY HH:mm")}</Td>
              <Td>{event.home_team}</Td>
              <Td>{event.away_team}</Td>
              <Td>
                <Button colorScheme="teal" size="sm">
                  Load Odds
                </Button>
              </Td>
            </Tr>
          );
        })}
        </Tbody>
        </Table>
        </TableContainer>
      }
    </Box>
  );
};

export default OddsApiScreen;
