// App.js
import React, { useEffect } from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import SignupScreen from "./screens/SignupScreen";
import DashboardScreen from "./screens/DashboardScreen";
import SmartBetPage from "./screens/SmartBetScreen";
import BetPage from "./screens/BetScreen";
import AccountScreen from "./screens/AccountScreen";
import SettingsScreen from "./screens/SettingsScreen";
import PortfolioScreen from "./screens/PortfolioScreen";
import PortfoliosScreen from "./screens/PortfoliosScreen";
import { AuthProvider, useAuth } from "./context/AuthContext";
import PrivateRoute, { AdminRoute } from "./components/PrivateRoute";
import PrivateLayout from "./components/PrivateLayout";
import AdminLayout from "./components/AdminLayout";
import Admin from "./screens/Admin";
import GamesAdmin from "./screens/Admin/Games";
import { theme } from "./components/PrivateLayout";
import ReportButton from "./components/ReportButton";
import NewPortfolio from "./screens/NewPortfolio";
import OddsApiScreen from "./screens/Admin/OddsApi";

function Logout() {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, []);
  return <></>;
}

function App() {
  return (
    <ChakraProvider
      theme={extendTheme({
        styles: {
          global: {
            body: {
              bg: theme.colors.background,
              color: "#FFFFFF",
            },
          },
        },
      })}
    >
      <Router>
        <AuthProvider>
          <ReportButton />
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/signup" element={<SignupScreen />} />

            <Route path="logout" element={<Logout />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <PrivateLayout>
                    <DashboardScreen />
                  </PrivateLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/account"
              element={
                <PrivateRoute>
                  <PrivateLayout>
                    <AccountScreen />
                  </PrivateLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <PrivateLayout>
                    <SettingsScreen />
                  </PrivateLayout>
                </PrivateRoute>
              }
            />

            <Route
              path="/portfolios"
              element={
                <PrivateRoute>
                  <PrivateLayout>
                    <PortfoliosScreen />
                  </PrivateLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/portfolios/new"
              element={
                <PrivateRoute>
                  <PrivateLayout>
                    <NewPortfolio />
                  </PrivateLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/portfolio/:portfolioId"
              element={
                <PrivateRoute>
                  <PrivateLayout>
                    <PortfolioScreen />
                  </PrivateLayout>
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/bets"
              element={
                <PrivateRoute>
                  <PrivateLayout>
                    <SmartBetPage />
                  </PrivateLayout>
                </PrivateRoute>
              }
            />
            <Route
              path="/bets/:portfolioId"
              element={
                <PrivateRoute>
                  <PrivateLayout>
                    <BetPage />
                  </PrivateLayout>
                </PrivateRoute>
              }
            />
            <Route path="/admin">
              <Route
                index
                element={
                  <AdminRoute>
                    <AdminLayout>
                      <Admin />
                    </AdminLayout>
                  </AdminRoute>
                }
              />
              <Route
                path="games"
                element={
                  <AdminRoute>
                    <AdminLayout>
                      <GamesAdmin />
                    </AdminLayout>
                  </AdminRoute>
                }
              />
              <Route
                path="odds"
                element={
                  <AdminRoute>
                    <AdminLayout>
                      <OddsApiScreen />
                    </AdminLayout>
                  </AdminRoute>
                }
              />
            </Route>
            <Route
                path="*"
                element={
                  <PrivateRoute>
                    <PrivateLayout>
                      <h1 color="white">404</h1>
                    </PrivateLayout>
                  </PrivateRoute>
                }
              />
          </Routes>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
